import React from 'react'
import { Card, Text, IconButton } from 'theme-ui'
import { FaPhone, FaEnvelope, FaLocationArrow } from 'react-icons/fa'
import Section from '@components/Section'
import useSiteMetadata from '@helpers-blog/useSiteMetadata'

const ContactInfo = () => {
  const { phone, address, email } = useSiteMetadata()

  const styles = {
    text: {
      fontSize:'1rem',
      display: 'flex',
      alignItems:'center'
    },
    section: {
      padding: '5px',
      width: '370px'
    }
  }

  return (
    <Section aside title='Les coordonnées'>
      <Card variant='paper' sx={styles.section}>
        {/* <Text variant='p'>Interested in working together?</Text>
        <Text variant='p'>
          Suspendisse potenti. Mauris mollis diam tempus ut.
        </Text> */}
        {phone && (
          <Text sx={ styles.text }>
            <IconButton variant='simple' role='presentation'>
              <FaPhone />
            </IconButton>
            {phone}
          </Text>
        )}
        {email && (
          <Text sx={ styles.text }>
            <IconButton variant='simple' role='presentation'>
              <FaEnvelope />
            </IconButton>
            {email}
          </Text>
        )}
        {address && (
          <Text sx={ styles.text }>
            <IconButton variant='simple' role='presentation'>
              <FaLocationArrow />
            </IconButton>
            {address}
          </Text>
        )}
      </Card>
    </Section>
  )
}

export default ContactInfo
