import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'
import { FaPhone, FaEnvelope, FaLocationArrow } from 'react-icons/fa'
import { IconButton } from 'theme-ui'


import './index.css'

const PageContact = props => (
  <Layout {...props}>
    <Seo title='Contact' />
    <Divider />
    <div style={{ marginTop: '70px' }}></div>
    <Stack>
      <Main>
        <PageTitle
          styles={{ textAlign: 'justify' }}
          header="Contactez-moi  🚀"
          subheader='Si vous avez des questions supplémentaires, des suggestions, besoin de simples conseils ou de renseignements, n’hésitez pas à me contacter par téléphone ou m’envoyer un courriel en complétant le formulaire de contact ci-dessous. '
        />
        <Divider />
        
        <h3 style={{ color: 'white', textAlign: 'center' }}>Les coordonnées</h3>
        <div className='contactInfos'>
          <div className='infosBox'>
            <IconButton variant='simple' role='presentation'>
                <FaPhone />
              </IconButton>
            <p>06 24 98 02 91</p>

          </div>
          <div className='infosBox'>
            <IconButton variant='simple' role='presentation'>
                <FaEnvelope />
              </IconButton>
            <p>chloe.carmona.immobilier@gmail.com</p>

          </div>
          <div className='infosBox'>
            <IconButton variant='simple' role='presentation'>
                <FaLocationArrow />
              </IconButton>
            <p>Situation Région bordelaise</p>

          </div>

        </div>
        <ContactForm />
      </Main>
      <Sidebar>
        {/* <Commitment /> */}
        <Divider />
        <ContactInfo />

      </Sidebar>
    </Stack>
  </Layout>
)

export default PageContact
